<template>
	<v-simple-table id="scrollTable">
		<WaitingSlot
		v-if="!isDataLoaded"
		class="tw-h-full"
		/>

		<template
		v-if="isDataLoaded"
		v-slot:default
		>
			<thead>
				<tr>
					<th
					v-for="(header, key) in headers"
					:key="key"
					class="font-weight-bold text-center"
					>
						{{ header }}
					</th>
				</tr>
			</thead>

			<tbody>
				<tr
				v-for="(value, key) in infos"
				:key="key"
				>
					<td>{{ value }}</td>

					<td
					v-for="(values, key2) in items"
					:key="key2"
					>
						{{ items[value][key2] }}
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>

export default {
	props: ["campaign"],
	data(){
		return {
			dashboard: {},
			cores: [],
			pks: [],
			materials: [],
			sortedCores: [],
			headers: [" "],
			coresLayers: [],
			items: [],
			infos: [
				"Réf",
				"Date",
				"Route",
				"Sens",
				"Voie",
				"PR",
				"Position",
				"Diamètre"
			],
			infosToIndex: {
				Réf: "businessId",
				Date: "createdAt",
				Sens: "direction",
				Voie: "lane",
				PR: "landmark",
				Position: "lateralPosition",
				Diamètre: "diameter"
			},
			mapComponentName: "structural-coring-campaign-map",
			isDataLoaded: false
		};
	},
	methods: {
		addHeaders(){
			this.cores.forEach(e => this.headers.push("C" + e.number));
		},
		addItems(){
			this.infos.forEach(info => {
				this.items[info] = [];
				this.cores.forEach(core => {
					if(info === "Route"){
						this.items[info].push(core.road.name);
						return;
					}
					if(info === "Date"){
						this.items[info].push(
							this.moment(core[this.infosToIndex[info]]).format("Do/MM/YYYY")
						);
						return;
					}
					this.items[info].push(core[this.infosToIndex[info]]);
				});
			});
			this.items.length = this.cores.length;
		}
	},
	mounted(){
		
		this.$api.cores.findByCampaign(this.campaign.id).then(data => {
			this.cores = data;
			this.addHeaders();
			this.addItems();
			this.isDataLoaded = true;
		});
	}
};
</script>

<style scoped>
.bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
}
th,
td {
  border: solid 1px lightgrey;
  min-width: 180px;
}
th {
  background-color: #2b0803;
  color: white !important;
  font-size: 24px;
}
tr > td:first-child {
  background-color: #ebe7e7;
  font-weight: bold;
}
table {
  border-radius: 4px !important;
}
</style>
