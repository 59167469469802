<template>
	<tbody>
		<tr>
			<td :rowspan="layersByCores + 2">
				<span
				class="font-weight-bold"
				style="font-size: 24px;"
				>
					BAU
				</span>
			</td>
		</tr>
		<tr v-if="sortOrder === 'PR + Abscisse'">
			<td style="border-right: 2px solid black; font-size: 16px">
				N° Carotte
			</td>
			<td
			v-for="(header, key) in headers"
			colspan="4"
			class="text-center font-weight-bold coreName"
			>
				{{ braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? 
					bauCores.find(core => core.landmark+'+'+core.abscissa === header) ? 
						bauCores.find(core => core.landmark+'+'+core.abscissa === header).businessId.slice(-6) : 
						''
					: ''
				}}
			</td>
		</tr>
		<tr
		v-for="n in layersByCores"
		:key="n+'BAU'"
		>
			<td 
			style="border-right: 2px solid black; font-size: 16px"
			>
				Couche {{ n }}
			</td>
			<template v-for="(header, key) in headers">
				<td
				class="text-center"
				style="border-left: 2px solid black; font-size: 20px"
				:style="braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? 'background-color:'+isGreyBackground(header, 'BAU', n) : ''"
				>
					{{ braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? getCumul(header, 'BAU', n) : '' }}
				</td>
				<td
				class="text-center tw-w-[56px] tw-min-w-[56px]"
				style="border-left: 2px solid black; font-size: 20px"
				:style="'background-color:'+isGreyBackground(header, 'Autre', n)"
				>
					{{ getMaterial(header, 'VR', n) }}
				</td>
				<td
				class="text-center"
				:style="braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? getAsbestos(header, 'BAU', n) === 'transparent' ? 'background-color:'+isGreyBackground(header, 'BAU', n) : 'background-color:'+ getAsbestos(header, 'BAU', n) : 'transparent'"
				/>
				<td
				class="text-center"
				:style="braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? getPAH(header, 'BAU', n) === 'transparent' ? 'background-color:'+isGreyBackground(header, 'BAU', n) : 'background-color:'+ getPAH(header, 'BAU', n) : 'transparent'"
				/>
				<td
				class="text-center"
				style="border-right: 2px solid black;"
				:style="braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? 'background-color:'+isGreyBackground(header, 'BAU', n) : ''"
				>
					{{ braceCores.find(core => core.landmark+'+'+core.abscissa === header) ? getTH(header, 'BAU', n) : '' }}
				</td>
			</template>
		</tr>
	</tbody>
</template>

<script>
export default {
	props: {
		isGreyBackground: {
			required: true,
			type: Function
		},
		getCumul: {
			required: true,
			type: Function
		},
		getAsbestos: {
			required: true,
			type: Function
		},
		getPAH: {
			required: true,
			type: Function
		},
		getTH: {
			required: true,
			type: Function
		},
		getMaterial: {
			required: true,
			type: Function
		},
		layersByCores: {
			required: true,
			type: Number
		},
		braceCores: {
			required: true,
			type: Array
		},
		bauCores: {
			required: true,
			type: Array
		},
		headers: {
			required: true,
			Type: Array
		},
		sortOrder: {
			required: true,
			Type: Boolean
		}
	},
	data(){
		return {
		};
	}
};
</script>

<style lang="scss" scoped>
th span 
{
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}

td span 
{
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}


.bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
}
td {
  border: solid 1px lightgrey;
  min-width: 50px;
}
th {
  background-color: #2b0803;
  color: white !important;
  font-size: 24px;
  border: 1px solid white
}
tr > td:first-child {
  background-color: #ebe7e7;
  font-weight: bold;
}
</style>
