<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		title="Résultats réseau"
		@clickBackButton="$router.push('/workspaces')"
		/>

		<CoringMacroMap
		class="tw-w-full tw-grow tw-flex"
		>
			<div class="menu">
				<CoringMacroFilterList/>
				<CoringMapFilter
				:isMacroProject="true"
				ref="filters"
				/>
			</div>

			<CoringMacroFilter/>
		</CoringMacroMap>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CoringMacroMap from "../../components/coring/macro/CoringMacroMap.vue";
import CoringMacroFilterList from "../../components/coring/macro/CoringMacroFilterList.vue";
import CoringMacroFilter from "../../components/coring/macro/CoringMacroFilter.vue";
import CoringMapFilter from "../../components/coring/map/Coring.map.filter.vue";
export default {
	components: {
		CoringMacroMap,
		CoringMacroFilterList,
		CoringMacroFilter,
		CoringMapFilter
	},
	computed: {
		...mapGetters("user", ["user"]),
		...mapGetters("workspace", ["filterOrga"]),
	},
	methods: {
		...mapActions("coringMacro", ["initStore", "purgeStore"]),
		...mapActions("user", ["waitLoadUser"]),
		...mapMutations("coring", ["REMOVE_ALL_FILTER_BY_VALUES"])
	},
	async mounted(){
		await this.waitLoadUser();
		let organizationId = null;
		if(this.$route.params?.id){
			organizationId = this.$route.params.id;
		}
		this.initStore({isUser: this.$store.state.user.current.job === null, id: organizationId === null ? this.user.userId : organizationId});
	},
	destroyed(){
		this.purgeStore();
		
		this.REMOVE_ALL_FILTER_BY_VALUES();
	}
};
</script>

<style lang="scss" scoped>
.menu {
	position: absolute !important;
	top: 10px !important;
	left: 16px !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
	justify-content: center !important;
	width: 400px !important;
	gap: 10px
}
</style>
